import React, { useState, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// Component
import ProjectHero from "../../section/projectpage/projectpage-hero"
import { BlockFullImage, BlockWideImage } from "../../components/project/image"
import Divider from "../../components/divider"
import ProjectAbout from "../../section/projectpage/projectpage-about"
import {
  OneColumnHeading,
  OneColumnTitle,
  OneColumnElementTitle,
} from "../../components/project/one-column"
import {
  TwoColumnHeading,
  TwoColumnTitle,
} from "../../components/project/two-column"
import Quote from "../../components/project/quote"
import { Display, DisplaySizePlus } from "../../components/project/display"
import ProjectNext from "../../section/projectpage/projectpage-next"
// Layout
import LayoutWrapper from "../../layouts/wrapper"
import ScrollWrapper from "../../layouts/scroll-wrapper"
import Metadata from "../../layouts/metadata"
import Header from "../../layouts/header"
import { Container, ProjectContainer } from "../../layouts/container"
import Footer from "../../layouts/footer"
import Social from "../../layouts/social"
import Video from "../../components/project/video"

// Data
import useUXClubData from "../../hook/projects/ux-club-data"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"

export default function Template() {
  // MENU: Data
  const {
    about_content,
    about_title,
    hero_image,
    project_description,
    project_name,
    role,
    timeline,
    tool,
    type,
    order1,
    body,
    body1,
  } = useUXClubData()

  const ProjectHeroData = {
    num: `0${Number(order1.text)}`,
    image_url: hero_image.fluid,
    name: project_name.raw,
    description: project_description.text,
  }
  const ProjectAboutData = {
    subtitle: "Overview",
    title: about_title.text,
    content: about_content.text,
    timeline: timeline.text,
    type: type.text,
    role: role.text,
    tool: tool.text,
  }
  const SliceData = body
  const SliceData2 = body1

  // MENU: Project Content

  const StartData = {
    num: SliceData[0].primary.num.text,
    heading: SliceData[0].primary.heading.text,
    content: SliceData[0].items[0].content.text,
    content2: SliceData[0].items[1].content.text,
    listTitle: SliceData[0].items[2].title.text,
    listContent: SliceData[0].items[2].content.text,
    listTitle2: SliceData[0].items[3].title.text,
    listContent2: SliceData[0].items[3].content.text,
    image_url: SliceData[1].items[0].image.fluid,
    image_alt: SliceData[1].items[0].image.alt,
  }

  const PrepareData = {
    num: SliceData[2].primary.num.text,
    heading: SliceData[2].primary.heading.text,
    content: SliceData[2].items[0].content.text,
    content2: SliceData[2].items[1].content.text,
    image_url: SliceData[3].items[0].image.fluid,
    image_alt: SliceData[3].items[0].image.alt,
  }

  const TestData = {
    num: SliceData[4].primary.num.text,
    heading: SliceData[4].primary.heading.text,
    content: SliceData[4].items[0].content.text,
    content2: SliceData[4].items[1].content.text,
    image_url: SliceData[5].items[0].image.fluid,
    image_alt: SliceData[5].items[0].image.alt,
  }

  const KnowData = {
    num: SliceData[6].primary.num.text,
    heading: SliceData[6].primary.heading.text,
    content: SliceData[6].items[0].content.text,
    image_url: SliceData[7].items[0].image.fluid,
    image_alt: SliceData[7].items[0].image.alt,
  }

  // MENU: Project Design

  const ShowData = {
    num: SliceData2[0].primary.num.text,
    heading: SliceData2[0].primary.heading.text,
    content: SliceData2[0].items[0].content.text,
    image_url: SliceData2[1].items[0].image.fluid,
    image_alt: SliceData2[1].items[0].image.alt,
    image_url2: SliceData2[1].items[1].image.fluid,
    image_alt2: SliceData2[1].items[1].image.alt,
  }

  const LearnData = {
    num: SliceData2[2].primary.num.text,
    heading: SliceData2[2].primary.heading.text,
    content: SliceData2[2].items[0].content.text,
  }

  return pug`
    Metadata(title=project_name.text)
    Header
    Social
    main
      ProjectHero(num=ProjectHeroData.num image_url=ProjectHeroData.image_url name=ProjectHeroData.name description=ProjectHeroData.description)
      Container.flex.justify-center.items-center.flex-col
        // About
        ProjectAbout(subtitle=ProjectAboutData.subtitle title=ProjectAboutData.title content=ProjectAboutData.content timeline=ProjectAboutData.timeline type=ProjectAboutData.type role=ProjectAboutData.role tool=ProjectAboutData.tool)

        ProjectContainer
          // Start
          OneColumnHeading(num=StartData.num heading=StartData.heading content=StartData.content)
            OneColumnElementTitle.my-8(content=StartData.content2)
            OneColumnElementTitle.my-8(title=StartData.listTitle content=StartData.listContent)
            OneColumnElementTitle.my-8(title=StartData.listTitle2 content=StartData.listContent2)
          BlockWideImage(image_url=StartData.image_url image_alt=StartData.image_alt)

          //Prepare
          OneColumnHeading(num=PrepareData.num heading=PrepareData.heading content=PrepareData.content)
            OneColumnElementTitle.my-8(content=PrepareData.content2)
          BlockWideImage(image_url=PrepareData.image_url image_alt=PrepareData.image_alt)

          //Test
          OneColumnHeading(num=TestData.num heading=TestData.heading content=TestData.content)
            OneColumnElementTitle.my-8(content=TestData.content2)
          BlockWideImage(image_url=TestData.image_url image_alt=TestData.image_alt)

          //Know
          OneColumnHeading(num=KnowData.num heading=KnowData.heading content=KnowData.content)
          BlockWideImage(image_url=KnowData.image_url image_alt=KnowData.image_alt)

          //Show
          OneColumnHeading(num=ShowData.num heading=ShowData.heading content=ShowData.content)
          BlockWideImage(image_url=ShowData.image_url2 image_alt=ShowData.image_alt2)
          BlockWideImage(image_url=ShowData.image_url image_alt=ShowData.image_alt)

          //Learn
          OneColumnHeading(num=LearnData.num heading=LearnData.heading content=LearnData.content)



      ProjectNext(index=order1.text)

  `
}
